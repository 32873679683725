import React, { useState } from "react";
import clsx from "clsx";
import { Text, Container, ButtonAlt, Input, Fieldset } from "@atoms";
import { AnimatePresence, m } from "framer-motion";
import { FormContainer } from "@molecules";

import { SITE } from "@constants";

// todo: better error handling

const FormSelect = ({ idx, heading, descriptor, options }) => {
  const [activeForm, setActiveForm] = useState(options?.length > 1 ? null : 0);
  const [form, setForm] = useState({});
  const handleSubmit = async e => {
    e.preventDefault();
    const { form: formFields, title, email } = options[activeForm];
    const renamedFields = Object.entries(form).reduce(
      (newForm, [key, value]) => ({
        ...newForm,
        [formFields.find(f => f.name === key).label]: value,
      }),
      {}
    );
    try {
      const data = await fetch("/api/submitForm", {
        method: "POST",
        body: JSON.stringify({
          form: renamedFields,
          email,
          title,
        }),
      });
      return await data.json();
    } catch (error) {
      return { error };
    }
  };
  return (
    <section
      className={clsx("min-h-[80vh] bg-midnight pb-16", { "pt-48": idx === 0 })}
    >
      <Container variant="xxs" className="mb-10">
        <Text
          variant={idx === 0 ? "h1" : "h2"}
          className="mb-6 text-center text-mint"
        >
          {heading}
        </Text>
        <Text
          richText
          className="prose-dark text-center prose-p:text-lg lg:prose-p:text-xl"
        >
          {descriptor}
        </Text>
      </Container>
      <AnimatePresence exitBeforeEnter>
        {activeForm != null && (
          <Container key={options[activeForm]?.uid} variant="xxs">
            {options.length > 1 && (
              <ButtonAlt
                onClick={() => setActiveForm(null)}
                reverse
                className="mt-16 md:mt-24"
              >
                Go Back
              </ButtonAlt>
            )}
            <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <FormContainer
                inverse={false}
                formState={[form, setForm]}
                onSubmit={handleSubmit}
                successMessage={
                  SITE === "default"
                    ? "Thank you for contacting us. We will be in touch with you soon."
                    : "Welcome to the Causie Community! We can't wait to share ideas and resources with you as you embark on your Causie journey. We're so glad you're here. -Rob"
                }
                successClass="mx-auto text-center"
                className={clsx("flex flex-wrap gap-x-8 gap-y-12 py-16 ", {
                  "mb-20 bg-mint p-10 md:p-16": SITE === "robActon",
                  "text-white": SITE !== "robActon",
                })}
              >
                {options[activeForm].form?.map((field, i) => {
                  const { uid } = field;
                  return (
                    <Fieldset
                      key={uid}
                      className={clsx("flex w-full", {
                        "w-full": field.type === "textarea",
                        "w-full md:flex-[1_1_calc(50%-1rem)]":
                          field.type !== "textarea",
                      })}
                    >
                      <Input
                        formState={[form, setForm]}
                        key={uid}
                        {...field}
                        className="w-full"
                        color="white"
                        group={
                          field.options && field.type === "radio"
                            ? field.options
                            : null
                        }
                      />
                    </Fieldset>
                  );
                })}
              </FormContainer>
            </m.div>
          </Container>
        )}
        {activeForm == null && options?.length > 1 && (
          <Container variant="sm">
            <m.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="my-16 grid grid-cols-1 gap-6 sm:grid-cols-2 md:my-24 lg:grid-cols-4"
            >
              {options.map((option, i) => {
                return (
                  <li key={option.uid}>
                    <button
                      type="button"
                      onClick={() => setActiveForm(i)}
                      className="group flex h-full flex-col items-center justify-center gap-3 border border-mint px-3 py-5 transition duration-300 hover:-translate-y-2 hover:border-carnation"
                    >
                      <Text
                        variant="h5"
                        className="text-center text-mint duration-300 group-hover:text-carnation"
                      >
                        {option.heading}
                      </Text>
                      <Text variant="sm" className="text-center text-white">
                        {option.descriptor}
                      </Text>
                      <ButtonAlt className="mt-auto">Contact Us</ButtonAlt>
                    </button>
                  </li>
                );
              })}
            </m.ul>{" "}
          </Container>
        )}
      </AnimatePresence>
    </section>
  );
};

export default FormSelect;
